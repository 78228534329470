import React, { useContext, useCallback, useEffect  } from 'react'
import { useFormContext } from "react-hook-form";
import { components, constants } from 'cng-web-lib'
import { Grid, Box, Typography } from '@material-ui/core'
import { A6TranslationContext } from './contexts/A6TranslationContext'
import {NaPartyMasterAutoCompleteField, NaStateCodeAutoCompleteField} from 'src/components/na'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import _ from 'lodash'

const {
  form: {
    field: {
      CngTextField,
      CngCheckboxField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField
    },
  },
  CngGridItem,
} = components

const { filter: { EQUAL } } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyType: "",
  partyName1: "",
  partyName2: "",
  address1: "",
  address2: "",
  city: "",
  stateCode: "",
  postalCode: "",
  countryCode: "",
  contactPerson: "",
  telNo: "",
  partyDropDown:"",
  saveFlag: false,
  partyIdentification:""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}



function Fields({ disabled, shouldHideMap, index }) {
  const { setValue, watch, trigger } = useFormContext();
  const { getTranslatedText } = useContext(A6TranslationContext)

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `taciOcnA6Party.${index}.${field}` : field
  }, [])

  const saveFlag = watch(getFieldName('saveFlag'))
  const partyType = watch(getFieldName('partyType'))
  const countryCode = watch(getFieldName('countryCode'))
  
  function handleApplyTemplate(template) {
    if (!template) {
      return
    }

    const {
      partyname1,
      partyname2,
      address1,
      address2,
      cityname,
      statecode,
      postalcode,
      countrycode,
      contactperson,
      telephonenumber
    } = template

    setValue(getFieldName("partyName1"), partyname1 || '', { shouldDirty: true })
    setValue(getFieldName("partyName2"), partyname2 || '', { shouldDirty: true })
    setValue(getFieldName("address1"), address1 || '', { shouldDirty: true })
    setValue(getFieldName("address2"), address2 || '', { shouldDirty: true })
    setValue(getFieldName("city"), cityname || '', { shouldDirty: true })
    setValue(getFieldName("postalCode"), postalcode || '', { shouldDirty: true })
    setValue(getFieldName("stateCode"), statecode || '', { shouldDirty: true })
    setValue(getFieldName("countryCode"), countrycode || '', { shouldDirty: true })
    setValue(getFieldName("contactPerson"), contactperson || '', { shouldDirty: true })
    setValue(getFieldName("telNo"), telephonenumber || '', { shouldDirty: true })
  }
 
  useEffect(() => {
    trigger([getFieldName("contactPerson"), getFieldName("telNo")]);
  }, []);

	return (
    <Box padding={2}>
      <Grid container spacing={2}>
        <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap?.partyType}>
          <CngCodeMasterAutocompleteField
            name={getFieldName('partyType')}
            label={getTranslatedText('A6', 'partyType')}
            disabled={disabled}
            codeType='A6_PARTY_TYPE'
            required
            size="small"
            key={partyType}
            onChange={(value) => setValue(getFieldName('partyType'), value)}
          />
        </CngGridItem>

        <CngGridItem xs={12} lg={8}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} md={3} shouldHide={shouldHideMap?.saveFlag}>
              <CngCheckboxField
                label={
                  <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                    {getTranslatedText('A6', 'saveFlag')}
                  </Typography>
                }
                name={getFieldName('saveFlag')}
                onChange={(e) => {
                  setValue(getFieldName('saveFlag'), e.target.checked)
                  if (!e.target.checked) {
                    setValue(getFieldName('partyIdentification'), '')

                  }
              }}
                size='small'
                style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md shouldHide={shouldHideMap?.partyIdentification}>
              <CngTextField
                name={getFieldName("partyIdentification")}
                label={getTranslatedText('A6', 'partyIdentification')}
                inputProps={{ maxLength: 35 }}
                disabled={disabled || !saveFlag}
                onBlur={(e) => {
                  setValue(getFieldName('partyIdentification'), e.target.value.toUpperCase().trim())
                }}
                required
                size="small"
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.partyDropDown}>
              <NaPartyMasterAutoCompleteField
                name={getFieldName("partyDropDown")}
                label={getTranslatedText('A6', 'partyDropDown')}
                disabled={disabled}
                onChange={(_, options) => { handleApplyTemplate(options?.data) }}
                lookupProps={{
                  filters: [{ field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }]
                }}
                size="small"
              />
            </CngGridItem>
          </Grid>
        </CngGridItem>

        <CngGridItem xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.partyName1}>
              <CngTextField
                name={getFieldName("partyName1")}
                inputProps={{ maxLength: 35 }}
                label={getTranslatedText('A6', 'partyName1')}
                disabled={disabled}
                onBlur={(e) => { setValue(getFieldName("partyName1"), e.target.value.toUpperCase().trim()) }}
                required
                size="small"
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.partyName2}>
              <CngTextField
                name={getFieldName("partyName2")}
                inputProps={{ maxLength: 35 }}
                label={getTranslatedText('A6', 'partyName2')}
                disabled={disabled}
                onBlur={(e) => { setValue(getFieldName("partyName2"), e.target.value.toUpperCase().trim()) }}
                size="small"
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.address1}>
              <CngTextField
                name={getFieldName("address1")}
                inputProps={{ maxLength: 35 }}
                label={getTranslatedText('A6', 'address1')}
                disabled={disabled}
                onBlur={(e) => { setValue(getFieldName("address1"), e.target.value.toUpperCase().trim()) }}
                required
                size="small"
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.address2}>
              <CngTextField
                name={getFieldName("address2")}
                inputProps={{ maxLength: 35 }}
                label={getTranslatedText('A6', 'address2')}
                disabled={disabled}
                onBlur={(e) => { setValue(getFieldName("address2"), e.target.value.toUpperCase().trim()) }}
                size="small"
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap?.city}>
              <CngTextField
                name={getFieldName("city")}
                inputProps={{ maxLength: 35 }}
                label={getTranslatedText('A6', 'city')}
                disabled={disabled}
                onBlur={(e) => { setValue(getFieldName("city"), e.target.value.toUpperCase().trim()) }}
                required
                size="small"
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap?.postalCode}>
              <CngTextField
                name={getFieldName("postalCode")}
                inputProps={{ maxLength: 9 }}
                label={getTranslatedText('A6', 'postalCode')}
                disabled={disabled}
                onBlur={(e) => { setValue(getFieldName("postalCode"), e.target.value.toUpperCase().trim()) }}
                size="small"
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap?.countryCode}>
              <CngCountryAutocompleteField
                name={getFieldName("countryCode")}
                inputProps={{ maxLength: 2 }}
                label={getTranslatedText('A6', 'countryCode')}
                disabled={disabled}
                key={countryCode}
                onChange={() => { setValue(getFieldName("stateCode"), "") }}
                lookupProps={{ label: (record) => (`${record.code};${record.descriptionEn}`) }}
                required
                size="small"
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap?.stateCode}>
              <NaStateCodeAutoCompleteField
                name={getFieldName("stateCode")}
                label={getTranslatedText('A6', 'stateCode')}
                disabled={disabled}
                countryCode={countryCode}
                key={countryCode}
                helperText="(* for US and Canadian States)"
                size="small"
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.contactPerson}>
              <CngTextField
                name={getFieldName("contactPerson")}
                inputProps={{ maxLength: 35 }}
                label={getTranslatedText('A6', 'contactPerson')}
                disabled={disabled}
                onBlur={(e) => { setValue(getFieldName("contactPerson"), e.target.value.toUpperCase().trim()); trigger([getFieldName("telNo"), getFieldName("contactPerson")]); }}
                size="small"
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.telNo}>
              <CngTextField
                name={getFieldName("telNo")}
                inputProps={{ maxLength: 12 }}
                label={getTranslatedText('A6', 'telNo')}
                disabled={disabled}
                onBlur={(e) => { setValue(getFieldName("telNo"), e.target.value.toUpperCase().trim()); trigger([getFieldName("contactPerson"), getFieldName("telNo")]); }}
                size="small"
              />
            </CngGridItem>
          </Grid>
        </CngGridItem>
      </Grid>
    </Box>
	)
}

const TaciOcnA6PartyFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TaciOcnA6PartyFormProperties
