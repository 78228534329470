import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import { format } from 'date-fns';
import moment from 'moment'


const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const today = new Date();
  today.setHours(24, 0, 0, 0);

  return Yup.object({ 
    carrierCode: Yup.string().required(requiredMessage).min(4,"Max and Min length allowed is 4").matches(/^[89].*$/,"Should start with 8 or 9").nullable(),
    vesselName: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/,"Cannot contain special characters like )(@*:etc.").nullable(),
    vesselCode: Yup.string().required(requiredMessage).matches(/^[0-9]+$/,"Must be numeric values in between 1 to 99999999").nullable(),
    voyageNo: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/,"Cannot contain special characters like )(@*:etc.").nullable(),
    nationality: Yup.string().required(requiredMessage).nullable(),
    masterOperator: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/,"Cannot contain special characters like )(@*:etc.").nullable(),
    convRefno: Yup.string().required(requiredMessage).min(6,"Must not be less than 6 characters").matches(/^[9][a-zA-Z0-9-., ]+$/,"Should start with 9 and Cannot contain special characters like )(@*:etc.").nullable(),
    crew: Yup.string().required(requiredMessage).matches(/^[0-9]+$/,"Must be numeric values in between 0 to 9999").nullable(),
    passengers: Yup.string().required(requiredMessage).matches(/^[0-9]+$/,"Must be numeric values in between 0 to 9999").nullable(),
    conveyanceType: Yup.string().required(requiredMessage).nullable(),
    containerShipment: Yup.boolean(),
    templateFlag: Yup.boolean(),
    templateName: Yup.string().when('templateFlag', {
      is: true,
      then: Yup.string().max(50, "Max length is 50").required(requiredMessage).matches(/^[a-zA-Z0-9\s]+$/, "Only alphanumeric characters are allowed."),
    }).nullable(),
    customsProc: Yup.string().required(requiredMessage).nullable(),
    charterInfo: Yup.string().nullable(),
    specialops: Yup.string().nullable().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like )(@*:etc."),
    cargoDescription: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like )(@*:etc.").nullable(),
    regNo: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/,"Cannot contain special characters like )(@*:etc.").nullable(),
    regDate: Yup.lazy((value) => value === '' || value === null ? Yup.string().required(requiredMessage) : Yup.date().required(requiredMessage).nullable().validFormat().typeError(dateTypeErrorMessage)),
    placeOfRegistry: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/,"Cannot contain special characters like )(@*:etc.").nullable(),
    nettWt: Yup.string().required(requiredMessage).matches(/^[1-9]\d{0,6}(\.\d{0,4})?%?$/,"Must be a numeric value between 1 and 9999999.9999").nullable(),
    grossWt: Yup.string().required(requiredMessage).matches(/^[1-9]\d{0,6}(\.\d{0,4})?%?$/,"Must be a numeric value between 1 and 9999999.9999").nullable(),
    cntrcargoWt: Yup.string().required(requiredMessage).matches(/^[0-9]\d{0,6}(\.\d{0,4})?%?$/,"Must be a numeric value between 0 and 9999999.9999").nullable(),
    cargoWt: Yup.string().required(requiredMessage).matches(/^[0-9]\d{0,6}(\.\d{0,4})?%?$/,"Must be a numeric value between 0 and 9999999.9999").nullable(),
    deadWt: Yup.string().required(requiredMessage).matches(/^[1-9]\d{0,6}(\.\d{0,4})?%?$/,"Must be a numeric value between 1 and 9999999.9999").nullable(),
    length: Yup.string().required(requiredMessage).matches(/^[1-9]\d{0,6}(\.\d{0,4})?%?$/,"Must be a numeric value between 1 and 9999999.9999").nullable(),
    safetycertNo: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/,"Cannot contain special characters like )(@*:etc.").nullable(),
    safetycertExpiryDate: Yup.lazy((value) => value === '' || value === null ? Yup.string().required(requiredMessage) : Yup.date().nullable().validFormat().min(today,`Safety Expiry Date must not be earlier than ${format(new Date(), 'yyyy-MM-dd')}`).typeError(dateTypeErrorMessage).required(requiredMessage)),
    radiocertNo: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/,"Cannot contain special characters like )(@*:etc.").nullable(),
    radiocertExpiryDate: Yup.lazy((value) => value === '' || value === null ? Yup.string().required(requiredMessage) : Yup.date().nullable().validFormat().min(today,`Radio Expiry Date must not be earlier than ${format(new Date(), 'yyyy-MM-dd')}`).typeError(dateTypeErrorMessage).required(requiredMessage)),
    equipcertNo: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/,"Cannot contain special characters like )(@*:etc.").nullable(),
    equipcertExpiryDate: Yup.lazy((value) => value === '' || value === null ? Yup.string().required(requiredMessage) : Yup.date().nullable().validFormat().min(today,`Eqipment Expiry Date must not be earlier than ${format(new Date(), 'yyyy-MM-dd')}`).typeError(dateTypeErrorMessage).required(requiredMessage)),
    loadlinecertNo: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/,"Cannot contain special characters like )(@*:etc.").nullable(),
    loadlinecertExpiryDate: Yup.lazy((value) => value === '' || value === null ? Yup.string().required(requiredMessage) : Yup.date().nullable().validFormat().min(today,`Loadline Expiry Date must not be earlier than ${format(new Date(), 'yyyy-MM-dd')}`).typeError(dateTypeErrorMessage).required(requiredMessage)),
    deratcertNo: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/,"Cannot contain special characters like )(@*:etc.").nullable(),
    deratcertExpiryDate: Yup.lazy((value) => value === '' || value === null ? Yup.string().required(requiredMessage) : Yup.date().nullable().required(requiredMessage).validFormat().min(today,`Derat Expiry Date must not be earlier than ${format(new Date(), 'yyyy-MM-dd')}`).typeError(dateTypeErrorMessage)),
    healthcertNo: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like )(@*:etc.").nullable(),
  //  healthcertExpiryDate: Yup.date().nullable().validFormat().min(today,`Health Cert Expiry Date must not be earlier than ${format(new Date(), 'yyyy-MM-dd')}`).typeError(dateTypeErrorMessage),
    civilcertNo: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like )(@*:etc.").nullable(),
   // civilcertExpiryDate: Yup.date().nullable().validFormat().min(today,`Civil Expiry Date must not be earlier than ${format(new Date(), 'yyyy-MM-dd')}`).typeError(dateTypeErrorMessage),
    departurePort: Yup.string().nullable().required(requiredMessage).nullable(),
    departureTerminal: Yup.string().when('customsProc',{
        is: (value)=>value==="22",
        then: Yup.string().required(requiredMessage),
        otherwise: Yup.string()
    }).matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like )(@*:etc.").nullable(),
    departurePier: Yup.string().nullable().matches(/^[0-9]*$/,"must be numeric values in between 0 to 9999"),
    etd: Yup.string().nullable().required(requiredMessage).test(
      'etd',
      'Please enter a valid Vessel Departure Date Time in yyyy-mm-dd HH:mm format.',
      value =>  {
        return moment(value, 'YYYY-MM-DD HH:mm').isValid()
      },
    ),
    otherwise: Yup.string(),
    arrivalPort: Yup.string().when('customsProc',{
      is: (value)=>value!=="22",
      then: Yup.string().required(requiredMessage),
      otherwise: Yup.string()
  }).nullable(),
    arrivalTerminal: Yup.string().when('customsProc',{
      is: (value)=>value!=="22",
      then: Yup.string().required(requiredMessage),
      otherwise: Yup.string()
  }).matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like )(@*:etc.").nullable(),
    arrivalPier: Yup.string().matches(/^[0-9]*$/,"Must be numeric values in between 0 to 9999").nullable(),
    eta: Yup.string().when('customsProc',{
      is: (value)=>value!=="22",
      then: Yup.string().required(requiredMessage).test(
        'eta',
        'Please enter a valid Estimated Date of Arrival in yyyy-mm-dd HH:mm format.',
        value => {
          return moment(value, 'YYYY-MM-DD HH:mm').isValid()
        },
      ),
      otherwise: Yup.string()
    }).nullable(),

    customsOfficeOfExit: Yup.string().when('customsProc',{
      is: (value)=>value==="22",
      then: Yup.string().required(requiredMessage),
      otherwise: Yup.string()
    }).nullable(),
    portOfDischarge:Yup.string().when('customsProc',{
      is: (value)=>value==="22",
      then: Yup.string().required(requiredMessage),
      otherwise: Yup.string()
    }).nullable(),
    a6aStatus: Yup.string().nullable(),
    messageFunction:Yup.string().when('a6aStatus',{
      is: (value)=>value==="1001",
      then: Yup.string().test('messageFunction','Rejected record should submit as Orginal ',function(value){
          if(value!=="9")
          {
            return false;
          }
          else{
            return true;
          }
      }), 
      otherwise: Yup.string().when('a6aStatus',{
        is: (value)=>value==="1003",
        then: Yup.string().test('messageFunction','cancelled record should submit as Orginal',function(value){
          if(value!=="9"){
            return false;
          }
          else{
            return true;
          }
        }),
        otherwise: Yup.string().when('a6aStatus',{
          is: (value)=>value==="1000" || value ==="1004",
          then: Yup.string().test('messageFunction','Record cannot submit as Orginal',function(value){
            if(value==="9"){
              return false;
            }
            else{
              return true;
            }
          })
        })
      })

    }).nullable().required(requiredMessage),
    civilcertExpiryDate: Yup.lazy((value) => value === '' || value === null ? Yup.string().nullable() :
      Yup.date().when('customsProc', {
      is: (civilcertExpiryDate) => civilcertExpiryDate!=='' && civilcertExpiryDate!==undefined,
      then: Yup.date().nullable().
      min(today,`Civil Expiry Date must not be earlier than ${format(new Date(), 'yyyy-MM-dd')}`)})),
    healthcertExpiryDate: Yup.lazy((value) => value === '' || value === null ? Yup.string().nullable() :
      Yup.date().when('customsProc', {
      is: (healthcertExpiryDate) => healthcertExpiryDate!=='' && healthcertExpiryDate!==undefined,
      then: Yup.date().nullable().
      min(today,`Health Cert Expiry Date not be earlier than ${format(new Date(), 'yyyy-MM-dd')}`)})),  
    overallLengthUom: Yup.string().required(requiredMessage).nullable(),

    taciOcnA6Party: Yup.array(Yup.object({
      saveFlag: Yup.boolean().nullable(),
      partyIdentification: Yup.string().when('saveFlag', {
        is: false,
        then: schema => schema,
        otherwise: Yup.string().when('saveFlag', {
          is: true,
          then: Yup.string().min(1, "Max length allowed is 1.").max(35, "Max length is 35.").required(requiredMessage).matches("[a-zA-Z0-9\s]$", "Only alphanumeric characters are allowed."),
        })
      }).nullable(),
      partyType: Yup.string().required(requiredMessage).nullable(),
      partyName1: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/, "Cannot contain special characters like ),(,@ etc.").nullable(),
      partyName2: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like ),(,@ etc.").nullable(),
      address1: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/, "Cannot contain special characters like ),(,@ etc.").nullable(),
      address2: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like ),(,@ etc.").nullable(),
      city: Yup.string().required(requiredMessage).matches(/^[a-zA-Z ]+$/, "Only alpha values are allowed").nullable(),
      stateCode: Yup.string().when('countryCode', {
        is: 'CA',
        then: Yup.string().required(requiredMessage),
        otherwise: Yup.string().when('countryCode', {
          is: 'US',
          then: Yup.string().required(requiredMessage)
        })
      }).nullable(),
      postalCode: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like ),(,@ etc.").nullable(),
      countryCode: Yup.string().nullable().required(requiredMessage).matches(/^[a-zA-Z ]+$/, "Only alpha values are allowed").nullable(),
      contactPerson: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like ),(,@ etc.").nullable()
      .test('both-or-neither', 'Both Contact Person and Mobile Number must be provided or both must be empty', function(value) {
        const { telNo } = this.parent;
        if ((value && !telNo) || (!value && telNo)) {
          return false;
        }
        return true;
      }),
      telNo: Yup.string().matches(/^[0-9]*$/, "Only numeric values are allowed").nullable()
      .test('both-or-neither', 'Both Contact Person and Mobile Number must be provided or both must be empty', function(value) {
        const { contactPerson } = this.parent;
        if ((value && !contactPerson) || (!value && contactPerson)) {
          return false;
        }
        return true;
      }),
    })
  ),

    taciOcnA6Route: Yup.array(),
    taciOcnA6Container: Yup.array(),
    taciOcnA6SN: Yup.array(),
  })
}

export default makeValidationSchema
